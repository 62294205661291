import $ from 'jquery';
import UIkit from 'uikit/dist/js/uikit.min'; // eslint-disable-line no-unused-vars
import './vendors/uikit-icons';
import './vendors/lazyload';
// --------------
// variables
// --------------

// --------------
// functions
// --------------

// hide on viewport
if ($(window).width() <= 960) {
  $('#nav__home').attr('hidden', 'true');
  $('.nav__standard').attr('hidden', 'true');
} else {
  $('#nav__home').removeAttr('hidden');
  $('.nav__standard').removeAttr('hidden');
}
$(window).on('resize', () => {
  if ($(window).width() <= 960) {
    $('#nav__home').attr('hidden', 'true');
    $('.nav__standard').attr('hidden', 'true');
  } else {
    $('#nav__home').removeAttr('hidden');
    $('.nav__standard').removeAttr('hidden');
  }
  // calcMenuOffset();
});

UIkit.util.on('.thirdcat__link', 'scrolled', function () {
  $('.thirdcat__link').removeClass('active');
  $(this).addClass('active');
});

// function calcMenuOffset() {
//   const menuLi = document.querySelectorAll('.cat__thirdcat ul li a');
//   const offset = document.querySelector('.cat__thirdcat').offsetHeight;
//   if (typeof menuLi !== undefined && menuLi.length > 0) {
//     for (let i = 0, len = menuLi.length; i < len; i++) {
//       menuLi[i].setAttribute('uk-scroll', `offset: ${offset};`);
//     }
//   }
// }

// $(() => {
//   calcMenuOffset();
// });
